import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { getWidgetConfig } from '@swapin-hub/api/config';

import { useConfigStore } from '@/stores/config';

export const useConfig = ({ key, params, query = {} } = {}) => {
  const configStore = useConfigStore();

  const configKey = key || configStore.key;
  const configParams = params || configStore.params;

  return useQuery({
    queryKey: ['config', configKey, configParams],
    queryFn: () => getWidgetConfig(configKey, configParams),
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: Number.POSITIVE_INFINITY,
    ...query,
  });
};

export const getConfigQueryData = ({ key, params } = {}) => {
  const queryClient = useQueryClient();
  const configStore = useConfigStore();

  const configKey = key || configStore.key;
  const configParams = params || configStore.params;

  return queryClient.getQueryData(['config', configKey, configParams]);
};
