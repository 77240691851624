<template>
  <RouterView />
  <ModalsContainer />
</template>

<script setup>
import { watch } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import { ModalsContainer } from '@swapin/ui/Modal';
import { setHeader } from '@swapin-hub/api';

import { CONFIG_KEY } from '@/const';
import { useConfigStore } from '@/stores/config';
import { useProfileStore } from '@/stores/profile';
import { useCoins, useConfig } from '@/queries';
import chatService from '@/services/chatService';

const router = useRouter();
const configStore = useConfigStore();
const profileStore = useProfileStore();

const searchParameters = new URL(window.location.href).searchParams;
const configKey = searchParameters.get('apiKey') || CONFIG_KEY;
const configParams = Object.fromEntries(searchParameters.entries());

setHeader('widget-api-key', configKey);

configStore.key = configKey;
configStore.params = configParams;
configStore.configUrl = window.location.search;

const { error: configError } = useConfig();
useCoins();

watch(configError, () => {
  router.replace({ name: 'not-available' });
}, { once: true });

const initChat = async () => {
  if (!import.meta.env.VITE_INTERCOM_KEY) {
    return;
  }

  await chatService.init();

  watch(() => profileStore.profile?.id, (newId) => {
    if (newId) {
      chatService.setUserData(profileStore.profile);
    } else {
      chatService.resetUserData();
    }
  });
};

// eslint-disable-next-line unicorn/prefer-top-level-await
initChat();
</script>
