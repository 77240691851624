import api from '@swapin-hub/api';

import AuthService from '@/services/authService';

const LOGIN_ROUTE_NAME = 'auth';

const updateAuthToken = (response) => {
  const token = response?.headers?.authorization?.split(' ')[1];

  if (token) {
    AuthService.setToken(token);
  }

  return response;
};

export default (router) => {
  let nextRoute = null;
  router.beforeEach((to) => {
    nextRoute = to;
  });

  api.interceptors.request.use((request) => {
    request.headers.Authorization = `Bearer ${AuthService.getToken()}`;
    return request;
  });

  api.interceptors.response.use((response) => updateAuthToken(response));

  api.interceptors.response.use(undefined, (error) => {
    updateAuthToken(error.response);

    if (error.response?.status === 401) {
      const { fullPath, name } = router.currentRoute.value;

      AuthService.logout();

      if (nextRoute?.name !== LOGIN_ROUTE_NAME && name !== LOGIN_ROUTE_NAME) {
        router.replace({
          name: LOGIN_ROUTE_NAME,
          query: {
            redirect: nextRoute?.fullPath || fullPath,
          },
        });
      }
    }

    return Promise.reject(error);
  });

  return api;
};
