import { createI18n } from 'vue-i18n';

import enMessages from './locales/en.json';

const DEFAULT_LOCALE = 'en';

const i18n = createI18n({
  legacy: false,
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  messages: {
    en: enMessages,
  },
});

export default i18n;
export { useI18n } from 'vue-i18n';
