import { loadScript } from '@swapin-hub/utils';

import { PROFILE_TYPE } from '@/const';

export default {
  isInitialScriptLoaded: !!window.Intercom,

  async init() {
    if (!this.isInitialScriptLoaded) {
      await loadScript(`https://widget.intercom.io/widget/${import.meta.env.VITE_INTERCOM_KEY}`);

      this.boot();

      this.isInitialScriptLoaded = true;
    }
  },

  boot() {
    window.Intercom?.('boot', {
      api_base: 'https://api-iam.eu.intercom.io',
      app_id: import.meta.env.VITE_INTERCOM_KEY,
      hide_default_launcher: true,
    });
  },

  open() {
    window.Intercom?.('show');
  },

  close() {
    window.Intercom?.('hide');
  },

  setUserData({ id, first_name, last_name, email, type }) {
    window.Intercom?.('update', {
      email,
      user_id: import.meta.env.VITE_ENV_DEV ? `.${id}` : id,
      name: `${first_name || ''} ${last_name || ''}`,
      external_type: type === PROFILE_TYPE.COMPANY ? 'business' : 'personal',
      external_is_widget_user: true,
    });
  },

  resetUserData() {
    window.Intercom?.('shutdown');

    this.boot();
  },
};
