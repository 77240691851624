import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getCoins } from '@swapin-hub/api/coins';

export const useCoins = () => {
  const { data: _data, ...query } = useQuery({
    queryKey: ['coins'],
    queryFn: getCoins,
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: Number.POSITIVE_INFINITY,
  });

  const data = computed(() => Object.values(_data.value || {}));

  const buyCoins = computed(() => data.value.filter((coin) => !!coin.buy));

  return {
    ...query,
    data,
    coinsObject: _data,
    buyCoins,
  };
};
