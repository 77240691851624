const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    // eslint-disable-next-line unicorn/no-array-for-each
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data === null || data === undefined ? '' : data;

    formData.append(parentKey, value);
  }
};

export const getFormData = (data) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};
