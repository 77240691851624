export const nextAnimationFrame = () => new Promise((resolve) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      resolve();
    });
  });
});

export const loadScript = (url) => new Promise((resolve, reject) => {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = url;

  script.addEventListener('load', () => resolve());
  script.addEventListener('error', () => reject(new Error(`Can't load script ${script.src}`)));

  document.head.append(script);
});
