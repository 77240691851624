export const PROFILE_TYPE = {
  PERSONAL: 1,
  COMPANY: 2,
};

export const PROFILE_STATUS = {
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  COMPLETE: 'complete',
  NO_SERVICE: 'no_service',
};

export const VERIFICATION_LEVEL = {
  NO_SERVICE: 0,
  STARTER: 1,
  BASIC: 2,
  FULL: 3,
};

export const VERIFICATION_LEVEL_KEYS = Object.fromEntries(
  Object.keys(VERIFICATION_LEVEL).map((key) => [key, key.toLowerCase()]),
);

export const VERIFICATION_ROUTE_NAME = {
  MAIN: 'verification',
  WELCOME: 'verification-welcome',
  START: 'verification-details',
  ADDRESS: 'verification-address',
  CITIZENSHIP: 'verification-citizenship',
  RESIDENCE_PERMIT: 'verification-residence-permit',
  IDENTITY: 'verification-identity',
  POA: 'verification-poa',
  FINANCIAL: 'verification-financial',
  SOF: 'verification-sof',
  CONFIRMATION: 'verification-confirmation',
  SETTING_UP_ACCOUNT: 'verification-setting-up-account',
  COMPLETE: 'verification-complete',
  REVIEW: 'verification-review',
  NO_SERVICE: 'verification-no-service',
  COUNTRY_MISMATCH: 'verification-country-mismatch',
};

export const CURRENCY = {
  EUR: 'EUR',
  GBP: 'GBP',
};

export const DEPOSIT_METHOD_TYPE = {
  SEPA: 'SEPA_BANK_TRANSFER',
  OPEN_BANKING: 'OPEN_BANKING',
};

export const CONFIG_KEY = 'swapin-widget-default';

export const MAIN_APP_LOGIN_URL = import.meta.env.VITE_ENV_DEV
  ? 'https://stan.piix.eu/login'
  : 'https://app.swapin.com/login';

export const YAPILY_URL = {
  TERMS: 'https://www.yapily.com/legal/end-user-terms',
  PRIVACY: 'https://www.yapily.com/legal/privacy-policy',
};
