import { nextTick } from 'vue';

const PAGES_TO_RESTORE = new Set(['transaction-history']);

const getElement = () => document.querySelector('.scroll-restore-element');

const initScrollRestore = (router) => {
  const scrollPositionsMap = new Map();

  router.options.scrollBehavior = () => {};

  router.beforeResolve((to, from) => {
    const element = getElement();
    const canSavePosition = PAGES_TO_RESTORE.has(from.name);

    if (!element || !canSavePosition) {
      return;
    }

    const position = {
      top: element.scrollTop,
      left: element.scrollLeft,
    };

    scrollPositionsMap.set(from.name, position);
  });

  router.afterEach((to) => {
    const isPopStateDetected = !!window.history.state.scroll;
    const position = isPopStateDetected ? scrollPositionsMap.get(to.name) : null;

    nextTick(() => {
      const element = getElement();

      if (element) {
        element.classList.add('hide-scrollbar');

        element.scrollTo(position || { top: 0, left: 0 });

        element.classList.remove('hide-scrollbar');
      }
    });
  });
};

export default initScrollRestore;
