import { getFormData } from '@swapin-hub/utils';

import api from './index';

export const getUser = () => api.get('me').then((response) => response.data);

export const getProfileStatus = () => api.get('me/status').then((response) => response.data.status);

export const getProfileLimits = () => api.get('me/limits').then((response) => response.data);

export const resetIdv = () => api.get('me/reset-idv').then((response) => response.data);

export const getUserLoginToken = () => api.get('me/login_token').then((response) => response.data);

export const checkProfile = (profile) => {
  const data = getFormData(profile);

  return api.post('me/check', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((response) => response.data);
};

export const saveProfile = (profile) => {
  const data = getFormData(profile);

  return api.post('me', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((response) => response.data);
};
