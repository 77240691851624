import { getActivePinia } from 'pinia';

export default () => {
  const pinia = getActivePinia();

  if (!pinia) {
    throw new Error('There is no stores');
  }

  const resetStores = {};

  // eslint-disable-next-line unicorn/no-array-for-each
  pinia._s.forEach((store, name) => {
    resetStores[name] = () => store.reset?.();
  });

  // eslint-disable-next-line unicorn/no-array-for-each
  resetStores.all = () => pinia._s.forEach((store) => store.reset?.());

  return resetStores;
};
