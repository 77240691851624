import api from './index';

export const login = ({ email, password }) => api.post('auth/login', { email, password });

export const loginByPin = ({ email, pin }) => api.post(`auth/login/${email}/${pin}`).then((response) => response.data);

export const logout = () => api.post('auth/logout');

export const sendLoginLink = (email, params) => api.post(`auth/send_login_link/${email}`, params)
  .then((response) => response.data);
