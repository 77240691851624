import api from './index';

const normalizeChainName = (chainName) => (chainName === 'Network' ? '' : chainName);

const getFullNetworkName = (coin) => {
  const { chain_name, network_name } = coin;
  const chainName = normalizeChainName(chain_name);

  if (chainName) {
    return `${network_name} (${chainName})`;
  }

  return network_name;
};

export const getCoins = () => api.get('coins')
  .then((response) => response.data.coins)
  // TODO: API - do it on API side !?
  .then((data) => Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, {
      ...value,
      chain_name: normalizeChainName(value.chain_name),
      full_network_name: getFullNetworkName(value),
      chain_id: Number.parseInt(value.chain_id, 16),
    }]),
  ));
