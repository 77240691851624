import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export const api2 = axios.create({
  baseURL: import.meta.env.VITE_API_URL_NEW,
});

export const setHeader = (key, value) => {
  api.defaults.headers.common[key] = value;
  api2.defaults.headers.common[key] = value;
};

export default api;
